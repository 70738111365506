import styled from '@mui/material/styles/styled'
import Link from '@components/UI/Link'

export const StyledLinkMegaMenu = styled(Link, {
  name: 'MegaMenu',
})(({ theme }) => ({
  color: theme.palette.common.white,
  ...theme.typography.body2,
  '&::after': {
    width: '0%',
  },
  '&:hover': {
    textDecoration: 'unset',
  },
  '&:hover::after': {
    width: '100%',
    visibility: 'visible',
  },
  [theme.breakpoints.up('md')]: {
    ...theme.typography.body1,
    color: theme.palette.neutral.main,
  },
}))

export const StyledListItem = styled('li', {
  name: 'listItem',
})(() => ({
  listStyle: 'none',
}))
