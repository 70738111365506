import { Button } from '@components/UI/Button'
import { Accordion, AccordionSummary, Typography, styled } from '@mui/material'

export const StyledWrapperGroupOfMenuItems = styled('div', {
  name: 'WrapperGroupOfMenuItems',
})(({ theme }) => ({
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 13, 12),
  ...theme.typography.body1,
  color: theme.palette.common.white,
  gap: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(12),
    color: theme.palette.text.primary,
    padding: theme.spacing(16),
  },
}))

export const StyledMenuItemAccordionSummaryTitle = styled(Typography, {
  name: 'MenuItemAccordionSummaryTitle',
})(({ theme }) => ({
  ...theme.typography.button,
}))

export const StyledSingleMenuItemTitle = styled(Typography, {
  name: 'StyledSingleMenuItemTitle',
})(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 700,

  [theme.breakpoints.up('md')]: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(12),
  },
}))

export const StyledContentItemGroup = styled('ul', {
  name: 'ContentItemGroup',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(4, 0),
  padding: 0,
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    '*': {
      ...theme.typography.body1,
    },
  },
}))

export const StyledContentCtaGroupOfMenuItems = styled('div', {
  name: 'ContentCtaGroupOfMenuItems',
  shouldForwardProp: prop => prop !== 'isAccordionItem',
})<{ isAccordionItem?: boolean }>(({ isAccordionItem, theme }) => ({
  display: 'inline-flex',
  margin: isAccordionItem ? theme.spacing(12, 0) : 0,

  [theme.breakpoints.down('md')]: {
    a: {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.common.white}`,
    },
  },
}))

export const StyledCta = styled(Button, {
  name: 'Cta',
})(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}))

export const StyledMenuItemAccordion = styled(Accordion, {
  name: 'MenuItemAccordion',
})(({ theme }) => ({
  color: theme.palette.common.white,
  width: '100%',
  padding: theme.spacing(0, 8),
  marginTop: 0,
  marginLeft: 0,
  boxShadow: 'none',
  ':before': {
    height: 0,
  },

  [theme.breakpoints.up('sm')]: {
    ':last-of-type': {
      margin: theme.spacing(0, 8),
      width: 'calc(100% - 32px)',
    },
  },
}))

export const StyledMenuItemAccordionSummary = styled(AccordionSummary, {
  name: 'MenuItemAccordionSummary',
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded?: boolean }>(({ theme }) => ({
  color: 'inherit',
  padding: 0,
  '&.collapsed': {
    borderBottom: `1px solid ${theme.palette.neutral.tones[40]}`,
  },
  '&.expanded': {
    minHeight: theme.spacing(12),
  },
}))

export const StyledMenuItemAccordionContent = styled('div', {
  name: 'MenuItemAccordionContent',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.neutral.tones[40]}`,
}))
