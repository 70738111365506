import styled from '@mui/material/styles/styled'

export const ContainerBannersWithIcons = styled('div', {
  name: 'BannersWithIcons',
  slot: 'Container',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

export const WrapperBannersWithIcons = styled('div', {
  name: 'BannersWithIcons',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(6)}`,
  color: theme.palette.text.dark.primary,
}))

export const TitleBannersWithIcons = styled('div', {
  name: 'BannersWithIcons',
  slot: 'Title',
})(() => ({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 14,
}))

export const ContentBannerWithIcons = styled('div', {
  name: 'BannersWithIcons',
  slot: 'Content',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} 0`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),

  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },

  [theme.breakpoints.up('md')]: {
    width: '90%',
  },

  [theme.breakpoints.up('lg')]: {
    width: '70%',
  },

  '> div:last-of-type': {
    display: 'none',
  },
}))
