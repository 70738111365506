import { Link } from '@components/common/Link/Link'
import Avatar from '@mui/material/Avatar'
import styled from '@mui/material/styles/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '../../../../../../features/ui/action'
import { openDrawerMegaMenuSelector } from '../../../../../../features/ui/selector'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import { ICMExternalChannel } from '../../../../../../types/cmsPlacement/CMExternalChannel'
import { ICMExternalPage } from '../../../../../../types/cmsPlacement/CMExternalPage'
import { isPictureMedia } from '../../../../../../types/cmsPlacement/Placement'
import { getSrcSetsImageFromCms } from '../../../../../../utils/url'
import { hostnameUrlsSelector, locationOriginSelector } from '@redux/selectors/site'
import { useRouter } from 'next/router'

const LinkMegaMenu = styled(Link, {
  name: 'CategoriesWithIcon',
  slot: 'AvatarLink',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: 0,
    height: 2,
    bottom: -4,
    left: 0,
    background: theme.palette.background.dark.secondary,
    visibility: 'hidden',
    transition: 'all 0.2s ease-in-out',
  },
  '&:hover::before': {
    visibility: 'visible',
    width: '100%',
  },
}))

const AvatarWithLink: React.FC<{
  teasableItem: ICMExternalPage | ICMExternalChannel
}> = ({ teasableItem }) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()
  const link = `${basePath}${teasableItem.formattedUrl || ''}`
  const picture = teasableItem.media?.find(isPictureMedia)?.uriTemplate || ''
  const { categoryName } = useSelector(openDrawerMegaMenuSelector)
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const srcSets = getSrcSetsImageFromCms('AVATAR_MEGA_MENU', picture, hostnameUrls)
  const mobile = srcSets?.mobile || ''
  const teasableItemLabel = teasableItem.title.split(' ').join('_')
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  const closeMegaMenu = () => {
    dispatch(setHamburgerMenuOpenStatus(false))
    dispatch(setCloseDrawerMegaMenu())
  }

  return (
    <>
      <Avatar alt="dcw-cms-content__fwb-image" src={mobile} sx={{ width: 48, height: 48 }} />
      <div onClick={closeMegaMenu}>
        <LinkMegaMenu
          href={link}
          data-element-id={`X_X_MainNav_${categoryName?.toUpperCase()}_${teasableItemLabel.toUpperCase()}`}
          data-description={`${teasableItemLabel.toUpperCase()}`}
          locale={locale as string}
          isLocaleDomain={isLocaleDomain}
          locationOrigin={locationOrigin}
        >
          {teasableItem.title}
        </LinkMegaMenu>
      </div>
    </>
  )
}

export default AvatarWithLink
