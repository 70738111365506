import styled from '@mui/material/styles/styled'
import React from 'react'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import CmsIcon from '../../../../CmsComponents/CmsIcon'

export const WrapperIconText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
  alignItems: 'center',
}))

export const ContentIcon = styled('div')(({ theme }) => ({
  display: 'inherit',
  color: theme.palette.primary.main,
  width: 24,
}))

export const ContentText = styled('div')(() => ({
  display: 'inherit',
}))

const IconWithText: React.FC<{ teasableItem: ILXTeaser }> = ({
  teasableItem,
}) => {
  const title = teasableItem.teaserTitle1 || ''
  const icon = teasableItem.teaserIcon || ''

  return (
    <WrapperIconText>
      {
        <ContentIcon>
          <CmsIcon teaserIcon={icon} />
        </ContentIcon>
      }
      <div>{title}</div>
    </WrapperIconText>
  )
}

export default IconWithText
