import { isCMProductList } from '../../../../../types/cmsPlacement/Placement'
import {
  ContentImage,
  ContentListOfProducts,
  ContentProduct,
  TitleListOfProducts,
  WrapperListOfProducts,
} from './ListOfProducts.style'

import { ProductMedia } from '@components/common/Media/ProductMedia'
import { Attachment, IServerProduct } from '@typesApp/product'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { openDrawerMegaMenuSelector } from '../../../../../features/ui/selector'
import { MegaMenuColumnComponentProps } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent'
import { ICMProductList } from '../../../../../types/cmsPlacement/CMProductList'
import { getAllProductAttributes } from '../../../../../utils/productAttributes'
import ScrollableContainer from '../../../../UI/ScrollableContainer'
import CmsAnchorMegaMenu from '../../../CmsComponents/CmsAnchorMegaMenu'

const formatListOfProducts = (data: ICMProductList['productData']): ICMProductListFormatted[] => {
  const formattedData = data.map(el => {
    const productAttributes = getAllProductAttributes((el as unknown as IServerProduct)?.cluster?.[0]?.attributes)
    const attachments = el?.cluster?.[0]?.attachments as unknown as IServerProduct['attachments']
    return {
      id: el?.uniqueID || '',
      modelName: productAttributes['MODEL_NAME'] || '',
      formattedUrl: el?.cluster?.[0]?.seo?.href! || '',
      attachments: attachments || [],
    }
  })

  return formattedData || []
}

interface ICMProductListFormatted {
  id: string
  modelName: string
  formattedUrl: string
  attachments: Attachment[]
}
const ListOfProducts: FC<MegaMenuColumnComponentProps> = ({ enableScroll, scrollGradientColor, placement }) => {
  const listOfProductItems = placement?.items?.find(isCMProductList)
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const { categoryName } = useSelector(openDrawerMegaMenuSelector)

  if (!listOfProductItems) return null
  const title = listOfProductItems.teaserTitle || ''
  const productListFormatted = formatListOfProducts(listOfProductItems.productData || [])

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <ScrollableContainer isEnabled={enableScroll} gradientColor={scrollGradientColor}>
      <WrapperListOfProducts {...metaData}>
        <TitleListOfProducts>{title}</TitleListOfProducts>
        <ContentListOfProducts>
          {productListFormatted.map(product => (
            <ContentProduct key={product.id}>
              <ContentImage>
                <ProductMedia attachments={product.attachments} sequence={'1.0'} usage={'PLP'} />
              </ContentImage>
              <CmsAnchorMegaMenu
                elementId={`X_X_MainNav_${categoryName?.toUpperCase()}_${product.modelName.toUpperCase()}`}
                to={product.formattedUrl}
              >
                {product.modelName}
              </CmsAnchorMegaMenu>
            </ContentProduct>
          ))}
        </ContentListOfProducts>
      </WrapperListOfProducts>
    </ScrollableContainer>
  )
}

export default ListOfProducts
