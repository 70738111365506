import {
  Dialog as MuiDialog,
  backdropClasses as MuiBackdropClasses,
  paperClasses as MuiPaperClasses,
  styled,
  Typography,
} from '@mui/material'
import { IconButton } from '../IconButton'

export const StyledDialog = styled(MuiDialog, {
  shouldForwardProp: props => props !== 'hugged',
})<{ hugged: boolean }>(({ theme, hugged }) => ({
  [`& .${MuiBackdropClasses.root}`]: {
    backgroundColor: theme.palette.neutral['0'],
    opacity: 0.5,
  },
  [`& .${MuiPaperClasses.root}`]: {
    padding: theme.spacing(8),
    margin: theme.spacing(8),
    boxShadow: theme.shadows[8],

    [theme.breakpoints.up('sm')]: {
      maxWidth: '633px',
    },

    [theme.breakpoints.between(0, 'sm')]: {
      margin: `${hugged ? 0 : ''}`,
      alignSelf: `${hugged ? 'flex-start' : ''}`,
      borderRadius: `${hugged ? 'initial' : ''}`,
      width: '100vw',
    },
  },
}))

export const StyledContentModalDialog = styled('div', {
  name: 'ModalDialog',
  slot: 'Content',
})(({ theme }) => ({
  padding: theme.spacing(8),
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
    width: '100%',
  },
}))

export const StyledModalDialogTitle = styled(Typography, {
  name: 'ModalDialog',
  slot: 'Title',
})(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(12),
  ...theme.typography.h6,
}))

export const StyledIconButton = styled(IconButton, {
  name: 'ModalDialog',
  slot: 'CloseButton',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
  top: theme.spacing(4),
}))
