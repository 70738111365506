import styled from '@mui/material/styles/styled'

export const WrapperListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'isTabletLandscape',
})<{
  isTabletLandscape?: boolean
}>(({ theme, isTabletLandscape }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 6),
  },

  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: isTabletLandscape ? 0 : theme.spacing(0, 16, 0, 4),
    margin: isTabletLandscape && 0,
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 16),
  },
}))

export const ContentListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Content',
  shouldForwardProp: (prop) => prop !== 'isTabletLandscape',
})<{
  isTabletLandscape?: boolean
}>(({ theme, isTabletLandscape }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(12),
  columnGap: theme.spacing(isTabletLandscape ? 12 : 4),

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '50% 50%',
  },

  [theme.breakpoints.up('md')]: {
    justifyItems: isTabletLandscape ? 'end' : 'center',
    gridTemplateColumns: isTabletLandscape ? '40% 40%' : '50% 50%',
    marginLeft: theme.spacing(isTabletLandscape ? 4 : 0),
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '45% 45%',
  },
}))
