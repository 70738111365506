import React from 'react'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import styled from '@mui/material/styles/styled'
import { ArrowSlimDownIcon } from '@components/UI/Icons/arrows'
import CmsIcon from '../../../../CmsComponents/CmsIcon'

export const ContentCmsIcon = styled('div')(() => ({
  height: 32,
  width: 32,
}))

export const ContentArrowIcon = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  height: 26,
}))

export const IconWithTextArrow: React.FC<{ teasableItem: ILXTeaser }> = ({
  teasableItem,
}) => {
  const icon = teasableItem.teaserIcon || ''
  const text = teasableItem.teaserText1.replace(/<.*?>/gi, '') || ''

  return (
    <>
      <ContentCmsIcon>
        <CmsIcon teaserIcon={icon} />
      </ContentCmsIcon>
      <div>{text}</div>
      <ContentArrowIcon>
        <ArrowSlimDownIcon />
      </ContentArrowIcon>
    </>
  )
}
