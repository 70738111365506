import React from 'react'
import {
  ContentListOfBrands,
  TitleListOfBrands,
  WrapperListOfBrands,
} from './ListOfBrands.style'
import CmsAnchorMegaMenu from '../../../CmsComponents/CmsAnchorMegaMenu'
import { openDrawerMegaMenuSelector } from '../../../../../features/ui/selector'
import { useSelector } from 'react-redux'
import { MegaMenuColumnComponentProps } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent'
import ScrollableContainer from '../../../../UI/ScrollableContainer'
import {
  isCMExternalChannel,
  isLXTeaser,
} from '../../../../../types/cmsPlacement/Placement'

const ListOfBrands: React.FC<MegaMenuColumnComponentProps> = ({
  enableScroll,
  placement,
  scrollGradientColor,
}) => {
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''

  const teaserItem = placement?.items?.find(isLXTeaser)
  const listOfbrandsItems = placement?.items?.find(isCMExternalChannel)

  const title = teaserItem?.teaserTitle1 || ''
  const brandList = listOfbrandsItems?.brandData || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }
  const { categoryName } = useSelector(openDrawerMegaMenuSelector)

  return (
    <ScrollableContainer
      gradientColor={scrollGradientColor}
      isEnabled={enableScroll}
    >
      <WrapperListOfBrands {...metaData}>
        <TitleListOfBrands>{title}</TitleListOfBrands>
        <ContentListOfBrands>
          {brandList.map((brand) => (
            <CmsAnchorMegaMenu
              key={brand.id}
              elementId={`X_X_MainNav_${categoryName?.toUpperCase()}_${brand.name.toUpperCase()}`}
              to={brand.formattedUrl}
            >
              {brand.name}
            </CmsAnchorMegaMenu>
          ))}
        </ContentListOfBrands>
      </WrapperListOfBrands>
    </ScrollableContainer>
  )
}

export default ListOfBrands
