import React, { useState } from 'react'
import { IPlacement, IPlacementItem, isCMCollection, isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { ICMExternalChannel } from '../../../../../types/cmsPlacement/CMExternalChannel'
import { ICMCollection } from '../../../../../types/cmsPlacement/CMCollection'
import { ICMChannel } from '../../../../../types/cmsPlacement/CMChannel'
import { ICMExternalPage } from '../../../../../types/cmsPlacement/CMExternalPage'
import {
  StyledContentCtaGroupOfMenuItems,
  StyledContentItemGroup,
  StyledMenuItemAccordion,
  StyledMenuItemAccordionContent,
  StyledMenuItemAccordionSummary,
  StyledWrapperGroupOfMenuItems,
  StyledMenuItemAccordionSummaryTitle,
  StyledSingleMenuItemTitle,
} from './GroupOfMenuItems.style'
import CmsAnchorMegaMenu from '@components/Cms/CmsComponents/CmsAnchorMegaMenu'
import CtaMegaMenu from '@components/Cms/CmsComponents/CmsCtaMegaMenu'
import { openDrawerMegaMenuSelector } from '../../../../../features/ui/selector'
import { useSelector } from 'react-redux'
import { ArrowDownIcon } from '@components/UI/Icons/arrows'
import { useTheme } from '@mui/material/styles'
import { camelCase } from '@components/common/components/CmsCta/helpers'

const GroupOfMenuItems: React.FC<{
  placement: IPlacement
  isAccordionEnabled?: boolean
  contentId?: string
}> = ({ contentId, placement, isAccordionEnabled }) => {
  const theme = useTheme()

  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const collectionItems = placement?.items?.filter(isCMCollection)
  const teaserItem = placement?.items?.find(isLXTeaser)
  const { categoryName } = useSelector(openDrawerMegaMenuSelector)
  const [expandedIds, setExpandedIds] = useState<any[]>([])
  if (!collectionItems) return null
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings || []
  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  const filterType = (teasableItems: IPlacementItem[]) => {
    const listType = ['CMChannel', 'CMExternalPage', 'CMExternalChannel']
    return (
      (teasableItems.filter(teasableItem => listType.includes(teasableItem.type)) as (
        | ICMChannel
        | ICMExternalChannel
        | ICMExternalPage
      )[]) || []
    )
  }
  const handleAccordionChange = (accordeonId: string | undefined) => {
    let expandedItems = [...expandedIds]
    if (expandedItems.includes(accordeonId)) {
      expandedItems = expandedItems.filter(item => item !== accordeonId)
    } else {
      expandedItems.push(accordeonId)
    }

    setExpandedIds([...expandedItems])
  }

  return (
    <>
      {collectionItems.map(
        item =>
          ((collectionItems.length == 1 || !isAccordionEnabled) && renderSingleMenuItem(item)) ||
          renderMultipleMenuItems(contentId, item)
      )}
    </>
  )

  function renderMultipleMenuItems(contentId: string | undefined, item: ICMCollection): JSX.Element {
    const accordionId = `${contentId || item.collectionTitle}-${item.id}`
    return (
      <StyledMenuItemAccordion
        key={item.id}
        onChange={() => handleAccordionChange(accordionId)}
        className={'menu-item-accordion'}
        expanded={expandedIds.includes(accordionId)}
      >
        <StyledMenuItemAccordionSummary
          className={expandedIds.includes(accordionId) ? 'expanded' : 'collapsed'}
          expandIcon={<ArrowDownIcon htmlColor={theme.palette.common.white} />}
          itemID={item.id}
        >
          <StyledMenuItemAccordionSummaryTitle variant="body1">
            {item.collectionTitle}
          </StyledMenuItemAccordionSummaryTitle>
        </StyledMenuItemAccordionSummary>
        <StyledMenuItemAccordionContent>
          <StyledContentItemGroup>
            {filterType(item.teasableItems).map(teasableItem => (
              <React.Fragment key={teasableItem.id}>
                <CmsAnchorMegaMenu
                  elementId={`X_X_MainNav_${categoryName?.toUpperCase()}_${teasableItem.title.toUpperCase()}`}
                  to={teasableItem.formattedUrl || ''}
                >
                  {teasableItem.title}
                </CmsAnchorMegaMenu>
              </React.Fragment>
            ))}
          </StyledContentItemGroup>
          <StyledContentCtaGroupOfMenuItems isAccordionItem>
            {teaserLXCallToActionSettings.map((actionSettings, index) => (
              <CtaMegaMenu
                key={`cms-content__${viewType}--cta-${index}`}
                data-element-id={`X_X_1Placement_Banner${index}_CTA`}
                actionSettings={actionSettings}
              />
            ))}
          </StyledContentCtaGroupOfMenuItems>
        </StyledMenuItemAccordionContent>
      </StyledMenuItemAccordion>
    )
  }

  function renderSingleMenuItem(item: ICMCollection): JSX.Element {
    return (
      <StyledWrapperGroupOfMenuItems {...metaData}>
        <div key={item.id}>
          <StyledSingleMenuItemTitle fontWeight="bold">{item.collectionTitle}</StyledSingleMenuItemTitle>
          <StyledContentItemGroup>
            {filterType(item.teasableItems).map(teasableItem => (
              <React.Fragment key={teasableItem.id}>
                <CmsAnchorMegaMenu
                  elementId={`X_X_MainNav_${categoryName?.toUpperCase()}_${teasableItem.title.toUpperCase()}`}
                  to={teasableItem.formattedUrl || ''}
                  dataName={camelCase(teasableItem.title)}
                >
                  {teasableItem.title}
                </CmsAnchorMegaMenu>
              </React.Fragment>
            ))}
          </StyledContentItemGroup>
        </div>
        <StyledContentCtaGroupOfMenuItems>
          {teaserLXCallToActionSettings.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              data-element-id={`X_X_1Placement_Banner${index}_CTA`}
              actionSettings={actionSettings}
              dataName={camelCase(actionSettings.callToActionText || '')}
            />
          ))}
          {item.teaserLXCallToActionSettings?.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              data-element-id={`X_X_1Placement_Banner${index}_CTA`}
              actionSettings={actionSettings}
              dataName={camelCase(actionSettings.callToActionText || '')}
            />
          ))}
        </StyledContentCtaGroupOfMenuItems>
      </StyledWrapperGroupOfMenuItems>
    )
  }
}

export default GroupOfMenuItems
