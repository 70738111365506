import styled from '@mui/material/styles/styled'
import { TextField } from '@components/UI/TextField'
import { FindStoreInput } from '@components/FindStore/FindStoreInput'

export const FindStoreInputStyled = styled(FindStoreInput, {
  name: 'BookAnAppointmentSearchBox',
  slot: 'TextField',
})(() => ({
  margin: '0',
}))

export const WrapperBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 10),
  gap: theme.spacing(4),
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 6, 6),
    gap: 0,
  },
}))

export const StyledTextField = styled(TextField, {
  name: 'BookAnAppointmentSearchBox',
  slot: 'TextField',
})(({ theme }) => ({
  '*::placeholder': {
    color: `${theme.palette.text.light.secondary} !important`,
    opacity: '100% !important',
  },
}))

export const TopContentBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'ContentTop',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const BottomContentBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'ContentBottom',
})(({ theme }) => ({
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const TitleBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Title',
  shouldForwardProp: (prop) => prop !== 'fontSize' && prop !== 'fontWeight',
})<{ fontSize?: number; fontWeight?: string }>(
  ({ fontSize = 12, fontWeight = 600 }) => ({
    fontSize,
    fontWeight,
    textTransform: 'uppercase',
  })
)

export const TextBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Text',
})(() => ({
  fontSize: 12,
}))

export const FormBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Form',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  marginTop: theme.spacing(4),
}))

export const IconTextBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'IconText',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const CtaBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Cta',
})(({ theme }) => ({
  marginTop: theme.spacing(3.5),
}))
