import {
  ContentIconsCategories,
  IconsLinkCategories,
  TitleCategories,
  WrapperCategoriesWithIcon,
} from './CategoriesWithIcon.style'

import AvatarWithLink from './components/AvatarWithLinks'
import CmsCtaMegaMenu from '../../../CmsComponents/CmsCtaMegaMenu'
import React from 'react'
import { MegaMenuColumnComponentProps } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent'
import ScrollableContainer from '../../../../UI/ScrollableContainer'
import { MenuColumnCtaContainer } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent.style'
import { ICMExternalPage } from '../../../../../types/cmsPlacement/CMExternalPage'
import { ICMExternalChannel } from '../../../../../types/cmsPlacement/CMExternalChannel'
import { isCMCollection, isLXTeaser } from '../../../../../types/cmsPlacement/Placement'

type IExternalCategories = (ICMExternalPage | ICMExternalChannel)[]

const CategoriesWithIcon: React.FC<MegaMenuColumnComponentProps> = ({
  enableScroll,
  scrollGradientColor,
  placement,
}) => {
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const collectionItem = placement?.items?.find(isCMCollection)
  const teaserItem = placement?.items?.find(isLXTeaser)

  if (!collectionItem) return null
  const title = collectionItem.collectionTitle || ''
  const collectionTeasableItems = (collectionItem.teasableItems as IExternalCategories) || []
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <>
      <ScrollableContainer isEnabled={enableScroll} gradientColor={scrollGradientColor}>
        <WrapperCategoriesWithIcon {...metaData}>
          <TitleCategories>{title}</TitleCategories>

          <ContentIconsCategories>
            {collectionTeasableItems.map(teasableItem => (
              <IconsLinkCategories key={teasableItem.id}>
                <AvatarWithLink teasableItem={teasableItem} />
              </IconsLinkCategories>
            ))}
          </ContentIconsCategories>
        </WrapperCategoriesWithIcon>
      </ScrollableContainer>
      <MenuColumnCtaContainer>
        {teaserLXCallToActionSettings.map((actionSettings, index) => (
          <CmsCtaMegaMenu
            key={`cms-content__${viewType}--cta-${index}`}
            data-element-id={`X_X_1Placement_Banner${index}_CTA`}
            actionSettings={actionSettings}
          />
        ))}
      </MenuColumnCtaContainer>
    </>
  )
}

export default CategoriesWithIcon
