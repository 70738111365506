import styled from '@mui/material/styles/styled'

export const WrapperListOfProducts = styled('div', {
  name: 'ListOfProducts',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: `0 ${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(10)}`,
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(6)} ${theme.spacing(6)}`,
  },
}))

export const TitleListOfProducts = styled('div', {
  name: 'ListOfProducts',
  slot: 'Title',
})(() => ({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 14,
}))

export const ContentListOfProducts = styled('div', {
  name: 'ListOfProducts',
  slot: 'ContentList',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const ContentProduct = styled('div', {
  name: 'ListOfProducts',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}))

export const ContentImage = styled('div', {
  name: 'ListOfProducts',
  slot: 'ContentImage',
})(() => ({
  width: 120,
  height: 60,
  fontSize: 0,
}))
