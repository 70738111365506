import { areEquals } from '@utils/common'

export const getHref = (currentPath: string | undefined, url: string, callToActionHash: string | undefined): string => {
  const hasActionHash = Boolean(callToActionHash)

  if (url && hasActionHash && !areEquals(currentPath, url)) {
    return `${url}#${callToActionHash}`
  }

  if (areEquals(currentPath, url) && hasActionHash) {
    return `#${callToActionHash}`
  }

  return url ?? '#NOT_FOUND_FORMATTED_URL'
}

export const isInCurrentPage = () => {}

export const camelCase = (inputString: string) => {
  let words = inputString.split(/[\s_-]+/)
  words[0] = words[0].toLowerCase()

  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  let camelCaseString = words.join('')

  return camelCaseString
}
