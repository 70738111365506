import React from 'react'
import {
  ContainerBannersWithIcons,
  ContentBannerWithIcons,
  TitleBannersWithIcons,
  WrapperBannersWithIcons,
} from './BannersWithIcons.style'
import CmsCtaMegaMenu from '../../../CmsComponents/CmsCtaMegaMenu'
import { IconWithTextArrow } from './components/IconWithTextArrow'
import { MegaMenuColumnComponentProps } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent'
import ScrollableContainer from '../../../../UI/ScrollableContainer'
import { MenuColumnCtaContainer } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent.style'
import { isCMCollection, isLXTeaser } from '../../../../../types/cmsPlacement/Placement'

const BannersWithIcons: React.FC<MegaMenuColumnComponentProps> = ({ enableScroll, scrollGradientColor, placement }) => {
  const collectionItem = placement?.items?.find(isCMCollection)

  if (!collectionItem) return null

  const viewType = placement?.viewtype || ''
  const placementName = placement?.name || ''
  const title = collectionItem.collectionTitle || ''
  const teasableItems = collectionItem.teasableItems?.filter(isLXTeaser) || []
  const itemLXCallToActionSettings = collectionItem.teaserLXCallToActionSettings || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <ContainerBannersWithIcons>
      <ScrollableContainer isEnabled={enableScroll} gradientColor={scrollGradientColor}>
        <WrapperBannersWithIcons {...metaData}>
          <TitleBannersWithIcons>{title}</TitleBannersWithIcons>
          <ContentBannerWithIcons>
            {teasableItems.map(teasableItem => (
              <IconWithTextArrow key={teasableItem.id} teasableItem={teasableItem} />
            ))}
          </ContentBannerWithIcons>
        </WrapperBannersWithIcons>
      </ScrollableContainer>
      <MenuColumnCtaContainer>
        {itemLXCallToActionSettings.map((actionSettings, index) => (
          <CmsCtaMegaMenu
            key={`cms-content__${viewType}--cta-${index}`}
            data-element-id={`X_X_1Placement_Banner${index}_CTA`}
            actionSettings={actionSettings}
          />
        ))}
      </MenuColumnCtaContainer>
    </ContainerBannersWithIcons>
  )
}

export default BannersWithIcons
