import { CSSObject, styled } from '@mui/material/styles'
import { CSSProperties } from '@mui/styled-engine'
import { debounce } from '@utils/helpers'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Z_INDEX_LV1 } from '../../../constants/ui'

const ScrollContainer = styled('div', {
  name: 'ScrollableContainer',
  slot: 'Container',
  shouldForwardProp: p => p !== 'extraCSSProps',
})<{ extraCSSProps?: CSSObject }>(({ extraCSSProps }) => ({
  position: 'relative',
  height: '100%',

  '& > *:first-of-type': {
    height: 'initial',
  },

  ...(extraCSSProps ? extraCSSProps : null),
}))

const ScrollableAreaContainer = styled('div', {
  name: 'ScrollableArea',
  slot: 'Container',
  shouldForwardProp: p => p !== 'hasGradient' && p !== 'gradientColor' && p !== 'isVisible',
})<{
  gradientColor: CSSProperties['color']
  hasGradient: boolean
  isVisible?: boolean
}>(({ gradientColor, hasGradient, isVisible, theme }) => ({
  overflow: 'visible',
  position: 'sticky',
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: theme.spacing(10),
  minHeight: theme.spacing(10),
  gap: theme.spacing(2),

  button: {
    zIndex: Z_INDEX_LV1,
    width: 16,
    padding: `0 ${theme.spacing(5)}`,
    color: theme.palette.text.dark.primary,
    opacity: isVisible ? '1' : '0',
    transition: '0.2s ease-in-out',
  },
  'button:hover': {
    opacity: 0.7,
  },
  'button[aria-hidden=true]': {
    opacity: 0,
    cursor: 'pointer',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: theme.spacing(19),
    background: hasGradient
      ? `linear-gradient(to bottom, transparent, ${gradientColor} 50%)`
      : `linear-gradient(to bottom, transparent 50%, ${gradientColor})`,
    pointerEvents: 'none',
  },
}))

interface ScrollableContainerProps {
  containerCSSProps?: CSSObject
  isEnabled?: boolean
  gradientColor?: CSSProperties['color']
}

const ScrollableContainer: FC<PropsWithChildren<ScrollableContainerProps>> = ({
  children,
  containerCSSProps,
  gradientColor = '#f6f6f6',
  isEnabled = true,
}) => {
  if (!isEnabled) {
    return <>{children}</>
  }

  const containerRef = useRef<HTMLDivElement | null>(null)

  const [isScrollEnabled, setScrollEnabled] = useState<boolean>(true)
  const [isCheckingScroll, setCheckingScroll] = useState<boolean>(true)
  const [scrollControlsVisibilityStatus, setScrollControlsVisibilityStatus] = useState<'up' | 'down' | 'both'>('down')

  const calcScrollControlsVisibilityStatus = (scrollPosition: number) => {
    const containerElement = containerRef.current!

    if (Math.ceil(scrollPosition + containerElement.clientHeight) >= containerElement.scrollHeight) {
      setScrollControlsVisibilityStatus('up')
    } else if (scrollPosition === 0) {
      setScrollControlsVisibilityStatus('down')
    } else {
      setScrollControlsVisibilityStatus('both')
    }
  }

  const shouldScrollBeEnabled = (containerElement: HTMLDivElement): boolean => {
    const { top } = containerElement.getBoundingClientRect()

    return containerElement.scrollHeight + top > window.innerHeight
  }

  useEffect(() => {
    const containerElement = containerRef.current!
    if (!containerElement) {
      return
    }

    const debouncedScrollListener = debounce(() => {
      calcScrollControlsVisibilityStatus(containerElement.scrollTop)
    }, 200)

    containerElement.addEventListener('scroll', debouncedScrollListener, false)

    return () => {
      containerElement.removeEventListener('scroll', debouncedScrollListener, false)
    }
  }, [window.innerHeight])

  useEffect(() => {
    if (isCheckingScroll) {
      return
    }

    const containerElement = containerRef.current
    if (!containerElement) {
      setCheckingScroll(true)
    } else {
      const newEnableScrollValue = shouldScrollBeEnabled(containerElement)

      setScrollEnabled(newEnableScrollValue)

      if (newEnableScrollValue) {
        containerElement.scroll({ top: 0 })
        calcScrollControlsVisibilityStatus(0)
      }
    }
  }, [children])

  useEffect(() => {
    if (!isCheckingScroll) {
      return
    }

    const containerElement = containerRef.current

    // Process cases when children changed, but component didn't unmount and mount from scratch.
    // * If scroll is already enabled and should be enabled for new children, leave state as is
    // * If scroll is already enabled, but should not be enabled for new children, set `isScrollEnable` false
    if (containerElement) {
      const newEnableScrollValue = shouldScrollBeEnabled(containerElement)

      setScrollEnabled(newEnableScrollValue)

      if (newEnableScrollValue) {
        containerElement.scroll({ top: 0 })
        calcScrollControlsVisibilityStatus(0)
      }
    }

    setCheckingScroll(false)
  }, [isCheckingScroll])

  return isScrollEnabled || isCheckingScroll ? (
    <ScrollContainer ref={containerRef} extraCSSProps={containerCSSProps}>
      {children}

      <ScrollableAreaContainer
        isVisible={isScrollEnabled}
        gradientColor={gradientColor}
        hasGradient={scrollControlsVisibilityStatus !== 'up'}
      ></ScrollableAreaContainer>
    </ScrollContainer>
  ) : (
    <>{children}</>
  )
}

export default ScrollableContainer
