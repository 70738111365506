import { Link } from '@components/common/Link/Link'
import styled from '@mui/material/styles/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '../../../../../../features/ui/action'
import { ICMExternalChannel } from '../../../../../../types/cmsPlacement/CMExternalChannel'
import BrandIcon from '../../../../../BrandIcon'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'

const BrandLink = styled(Link, {
  name: 'ListOfBrandIcon',
  slot: 'BrandLink',
  shouldForwardProp: prop => prop !== 'width',
})<{ width?: string; height?: string }>(({ width, height }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: width ? width : '100%',
  height: height ? height : 'auto',
}))

const BrandIconLink: React.FC<{
  teasableItem: ICMExternalChannel
  width?: string
  height?: string
  isTabletLandscape?: boolean
  lazy?: boolean
}> = ({ teasableItem, width, height, isTabletLandscape, lazy }) => {
  const dispatch = useDispatch()
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  const brandName = teasableItem?.externalId?.split('_').pop() || ''
  const formattedUrl = teasableItem?.formattedUrl || ''
  const link = `${formattedUrl || ''}`

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }

  return (
    <BrandLink
      href={link}
      onClick={closeMegaMenu}
      width={width}
      height={height}
      data-element-id="X_X_MainNav_Brand"
      data-description={brandName}
      locale={locale as string}
      isLocaleDomain={isLocaleDomain}
      locationOrigin={locationOrigin}
    >
      <BrandIcon name={brandName} width={142} height={55} isTabletLandscape={isTabletLandscape} lazy={lazy} />
    </BrandLink>
  )
}

export default BrandIconLink
