import styled from '@mui/material/styles/styled'

export const WrapperCategoriesWithIcon = styled('div', {
  name: 'CategoriesWithIcon',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(10)}`,
  fontSize: 14,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(6)}`,
  },
}))

export const TitleCategories = styled('div', {
  name: 'CategoriesWithIcon',
  slot: 'Title',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}))

export const ContentIconsCategories = styled('div', {
  name: 'CategoriesWithIcon ',
  slot: 'Content',
})(({ theme }) => ({
  paddingBottom: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} 0`,
  },
}))

export const IconsLinkCategories = styled('div', {
  name: 'CategoriesWithIcon',
  slot: 'IconLinks',
})(({ theme }) => ({
  display: 'inherit',
  fontSize: 16,
  gap: theme.spacing(4),
  alignItems: 'center',
}))
