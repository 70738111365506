import {
  ContentLoader,
  MegaMenuContainer,
  MenuFirstColumn,
  MenuSecondColumn,
  MenuThirdColumn,
} from './MegaMenuContent.style'

import { IContentMegaMenu } from '@typesApp/cms'

import { IPlacement } from '@typesApp/cmsPlacement/Placement'

import BannersWithIcons from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/BannersWithIcons'
import BigMenuBanner from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/BigMenuBanner'
import BookAnAppointmentSearchBox from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/BookAnAppointmentSearchBox'
import CategoriesWithIcon from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/CategoriesWithIcon'
import GroupOfMenuItems from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/GroupOfMenuItems'
import ListOfBrandIcon from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/ListOfBrandIcons'
import ListOfBrands from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/ListOfBrands'
import ListOfProducts from '@components/Cms/CmsPlacement/CmsMegaMenuBanner/ListOfProducts'
import useBreakpoints from '@hooks/useBreakpoints'
import { CSSProperties } from '@mui/styled-engine'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { FC, useMemo } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export interface MegaMenuColumnComponentProps {
  enableScroll: boolean
  scrollGradientColor: CSSProperties['color']
  placement: IPlacement
  isAccordionEnabled?: boolean
  contentId?: string
}

const sectionsWithHiddenSecondColumn = ['Lenses', 'Brands']

const MegaMenuContent: FC<{
  content?: IContentMegaMenu
  isAccordionEnabled?: boolean
  contactUsComponent?: React.ReactNode
  isContentVisible?: boolean
}> = ({ content, isAccordionEnabled = false, contactUsComponent }) => {
  const { isDesktop, isTabletLandscape } = useBreakpoints()
  const contentId = content?.id

  const placements = useMemo(() => content?.placements ?? [], [content])
  const sectionName = content?.name ?? ''
  const enableColumnScroll = isDesktop
  const hideSecondColumn = !isDesktop && sectionsWithHiddenSecondColumn.includes(sectionName)

  const firstColumnPlacements = placements.find(placement => placement.name === 'header_ddm_first_column')
  const secondColumnPlacements = placements.find(placement => placement.name === 'header_ddm_second_column')
  const thirdColumnPlacements = placements.find(placement => placement.name === 'header_ddm_third_column')

  return (
    <>
      {!placements.length && (
        <ContentLoader>
          <SkeletonTheme baseColor="transparent" highlightColor={'rgba(0, 0, 0, 0.1)'}>
            <Skeleton width="100%" height="100vh" />
          </SkeletonTheme>
        </ContentLoader>
      )}
      <MegaMenuContainer>
        <MenuFirstColumn isOverflowHidden={enableColumnScroll} isTabletLandscape={isTabletLandscape}>
          {firstColumnPlacements && (
            <GetMegaMenuPlacement
              enableScroll={enableColumnScroll}
              scrollGradientColor="white"
              placement={firstColumnPlacements}
              isAccordionEnabled={isAccordionEnabled}
              contentId={contentId}
            />
          )}
        </MenuFirstColumn>

        {!hideSecondColumn && (
          <MenuSecondColumn isOverflowHidden={enableColumnScroll}>
            {secondColumnPlacements && (
              <GetMegaMenuPlacement
                enableScroll={enableColumnScroll}
                scrollGradientColor="#f6f6f6"
                placement={secondColumnPlacements}
                isAccordionEnabled={isAccordionEnabled}
                contentId={contentId}
              />
            )}
            {contactUsComponent}
          </MenuSecondColumn>
        )}

        <MenuThirdColumn>
          {thirdColumnPlacements && (
            <GetMegaMenuPlacement
              enableScroll={enableColumnScroll}
              placement={thirdColumnPlacements}
              isAccordionEnabled={isAccordionEnabled}
              contentId={contentId}
            />
          )}
        </MenuThirdColumn>
      </MegaMenuContainer>
    </>
  )
}

const getColumnComponentByViewType = (viewType: IViewType): FC<MegaMenuColumnComponentProps> | null => {
  switch (viewType) {
    case 'categories-with-icon':
      return CategoriesWithIcon
    case 'group-of-menu-items':
      return GroupOfMenuItems
    case 'big-menu-banner':
      return BigMenuBanner
    case 'banners-with-icons':
      return BannersWithIcons
    case 'list-of-products':
      return ListOfProducts
    case 'list-of-brand-icons':
      return ListOfBrandIcon
    case 'list-of-brands':
      return ListOfBrands
    case 'book-an-appointment-plus-search-box':
      return BookAnAppointmentSearchBox
    default:
      return null
  }
}

const GetMegaMenuPlacement: FC<
  | {
      enableScroll?: boolean
      scrollGradientColor?: undefined
      placement: IPlacement
      isAccordionEnabled?: boolean
      contentId?: string
    }
  | MegaMenuColumnComponentProps
> = ({ enableScroll = false, scrollGradientColor, placement, isAccordionEnabled = false, contentId }) => {
  const viewType = placement?.viewtype
  const ColumnComponent = getColumnComponentByViewType(viewType)

  if (!ColumnComponent) return null

  return (
    <ColumnComponent
      enableScroll={enableScroll as boolean}
      scrollGradientColor={scrollGradientColor as CSSProperties['color']}
      placement={placement}
      isAccordionEnabled={isAccordionEnabled}
      contentId={contentId}
    />
  )
}

export default MegaMenuContent
