import {
  accordionClasses as MuiAccordionClasses,
  accordionSummaryClasses as MuiAccordionSummaryClasses,
  styled,
} from '@mui/material'

export const MegaMenuContainer = styled('div', {
  name: 'MegaMenu',
  slot: 'Container',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  height: '100%',
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.background.primary,
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
  },

  [`&.${MuiAccordionClasses.root}`]: {
    minHeight: 'auto',
  },

  [`&.${MuiAccordionClasses.expanded}`]: {
    margin: theme.spacing(0),
    minHeight: 'auto',
  },

  [`.${MuiAccordionSummaryClasses.content}`]: {
    minHeight: 46,
    alignItems: 'center',
    margin: theme.spacing(0),

    '&.Mui-expanded': {
      margin: theme.spacing(0),
    },
  },

  [`.${MuiAccordionSummaryClasses.root}`]: {
    margin: theme.spacing(0),
    minHeight: 'auto',
  },

  [`.${MuiAccordionSummaryClasses.expanded}`]: {
    minHeight: 46,
    alignItems: 'center',
    margin: theme.spacing(0),
  },
}))

// Base styled component for styled components of 1st & 2nd menu columns
const MegaMenuColumn = styled('div', {
  name: 'MegaMenu',
  slot: 'Column',
  shouldForwardProp: prop => prop !== 'isOverflowHidden',
})<{ isOverflowHidden?: boolean }>(({ isOverflowHidden, theme }) => ({
  overflow: isOverflowHidden ? 'hidden' : 'auto',
  position: 'relative',
  height: 'auto',
  padding: theme.spacing(0),

  [theme.breakpoints.up('md')]: {
    height: 'inherit',
  },
}))

export const MenuFirstColumn = styled(MegaMenuColumn, {
  name: 'MegaMenu',
  slot: 'FirstColumn',
  shouldForwardProp: prop => prop !== 'isTabletLandscape',
})<{
  isTabletLandscape?: boolean
}>(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  padding: theme.spacing(0, 8),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.common.white,
  },
}))

export const MenuSecondColumn = styled(MegaMenuColumn, {
  name: 'MegaMenuSecondColumn',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.background[2],
    padding: 0,
  },

  [`.${MuiAccordionClasses.root}`]: {
    '&.Mui-expanded': {
      margin: theme.spacing(0),
    },

    '&.menu-item-accordion': {
      padding: theme.spacing(0, 22),
    },
  },
}))

export const MenuThirdColumn = styled('div', {
  name: 'MegaMenu',
  slot: 'ThirdColumn',
})(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'relative',

  '&>div': {
    display: 'flex',
    flexDirection: 'column',

    'div:first-of-type': {
      flexGrow: '1',
    },
  },

  img: {
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'middle',
  },
}))

export const MenuColumnCtaContainer = styled('div', {
  name: 'MegaMenu',
  slot: 'CtaContainer',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
    marginTop: 'auto',
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    // solution for having a minimum bottom margin together with 'auto' value
    '& > *:first-of-type': {
      marginTop: theme.spacing(6),
    },
  },
}))

export const ScrollableContentWrapper = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 100,
  background: 'linear-gradient(to bottom, transparent, #f6f6f6 60%)',
  gap: theme.spacing(2),
  button: {
    width: 16,
    color: theme.palette.text.dark.primary,
  },
}))

export const ContentLoader = styled('div')(() => ({
  position: 'absolute',
  width: '100vw',
}))
