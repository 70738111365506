import React from 'react'
import { ContentListOfBrandIcon, WrapperListOfBrandIcon } from './ListOfBrandIcon.style'
import CmsCtaMegaMenu from '../../../CmsComponents/CmsCtaMegaMenu'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import BrandIconLink from './components/BrandIconLink'
import { MegaMenuColumnComponentProps } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent'
import ScrollableContainer from '../../../../UI/ScrollableContainer'
import { MenuColumnCtaContainer } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent.style'
import useTheme from '@mui/material/styles/useTheme'
import { isCMCollection, isCMExternalChannel, isLXTeaser } from '../../../../../types/cmsPlacement/Placement'

const ListOfBrandIcon: React.FC<MegaMenuColumnComponentProps> = ({ enableScroll, placement, scrollGradientColor }) => {
  const theme = useTheme()
  const { isDesktop, isTabletLandscape } = useBreakpoints()

  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const collectionItem = placement?.items?.find(isCMCollection)
  const teaserItem = placement?.items?.find(isLXTeaser)

  if (!collectionItem) return null
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings || []
  const teasableItems = collectionItem.teasableItems?.filter(isCMExternalChannel) || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <>
      <ScrollableContainer
        containerCSSProps={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(6),
        }}
        gradientColor={scrollGradientColor}
        isEnabled={enableScroll}
      >
        <WrapperListOfBrandIcon {...metaData} isTabletLandscape={isTabletLandscape}>
          <ContentListOfBrandIcon isTabletLandscape={isTabletLandscape}>
            {teasableItems.map(teasableItem => (
              <React.Fragment key={teasableItem.id}>
                <BrandIconLink
                  teasableItem={teasableItem}
                  width={isTabletLandscape ? '90%' : '100%'}
                  isTabletLandscape={isTabletLandscape}
                />
              </React.Fragment>
            ))}
          </ContentListOfBrandIcon>
        </WrapperListOfBrandIcon>
      </ScrollableContainer>
      {isDesktop && (
        <MenuColumnCtaContainer>
          {teaserLXCallToActionSettings?.map((actionSettings, index) => (
            <CmsCtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              data-element-id={`X_X_1Placement_Banner${index}_CTA`}
              actionSettings={actionSettings}
            />
          ))}
        </MenuColumnCtaContainer>
      )}
    </>
  )
}

export default ListOfBrandIcon
